<template>
  <my-ticket-header />
  <div class="my-ticket-address">
    <div class="my-ticket-address--container">
      <div class="my-ticket-address--container__title">
        <img src="@/assets/images/ic-back.svg" alt="back" @click="onBackClick" />
        <span>{{ $t("myTicket.titles.modifyAddress") }}</span>
      </div>
      <my-ticket-info :ticket="ticketStore.ticket" />
      <div class="my-ticket-address--container__actual-address">
        <span>Dirección actual</span>
        <hr />
        <span class="text-sm">
          {{ ticketStore.ticket.attendee.address }}
        </span>
        <span class="text-sm">
          {{ ticketStore.ticket.attendee.locality }},
          {{ ticketStore.ticket.attendee.state }},
          {{ ticketStore.ticket.attendee.country }}
        </span>
        <span class="text-sm">
          {{ ticketStore.ticket.attendee.postal_code }}
        </span>
      </div>
      <div class="my-ticket-address--container__new-data">
        <span>{{ $t("myTicket.titles.newData") }}</span>
        <hr />
        <my-ticket-input
          v-model="address"
          :placeholder="$t('myTicket.labels.address')"
          :label="$t('myTicket.labels.address')"
          :type="'text'"
          :validator="validators.address"
          :show-error="formSubmitted && !validators.personal_address"
          :error-message="$t('wegow.payment.nominativeErrors.address')"
        />
        <my-ticket-input
          v-model="postalCode"
          :placeholder="$t('myTicket.labels.postalCode')"
          :label="$t('myTicket.labels.postalCode')"
          :type="'text'"
          :validator="validators.postal_code"
          :show-error="formSubmitted && !validators.postal_code"
          :error-message="$t('wegow.payment.nominativeErrors.postal_code')"
          max-length="8"
        />
        <span class="label">Ciudad</span>
        <cities-search
          v-model:value="location"
          border-input="border-transparent"
          @change="onUpdateLocation($event)"
        />
      </div>
      <wegow-loader v-if="loading" class="loader" />
      <wegow-button
        v-else
        class="my-ticket-address--container__button"
        background="opacity"
        color="topaz"
        :disabled="isButtonDisabled"
        :text="$t('myTicket.login.continue')"
        @click="onContinueClick"
      />
      <span class="my-ticket-address--container__back" @click="onBackClick">
        {{ $t("myTicket.titles.otherProcedures") }}
      </span>
    </div>
  </div>
</template>

<script setup>
import MyTicketInfo from "@/components/my-ticket/MyTicketInfo.vue";
import MyTicketInput from "@/components/my-ticket/MyTicketInput.vue";
import MyTicketHeader from "@/components/my-ticket/MyTicketHeader.vue";
import WegowButton from "@/components/general/forms/WegowButton";
import WegowLoader from "@/components/general/ui/WegowLoader.vue";
import CitiesSearch from "@/components/general/forms/selectors/CitiesSearch.vue";

import { ref, computed, reactive } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useTicketStore } from "@/stores/modules/ticket";
import { usePurchase } from "@/composables/usePurchase";
import { useUserStore } from "@/stores/modules/user";
import { useModalHandler } from "@/plugins/useModalHandler";
import { formatCurrency } from "@/plugins/currency";

const router = useRouter();
const { t } = useI18n();
const { isValidCif, isValidNif, isValidNie, isValidPassport } = usePurchase();

const ticketStore = useTicketStore();
const userStore = useUserStore();
const { openErrorModal } = useModalHandler();

const address = ref("");
const locality = ref("");
const province = ref("");
const country = ref("");
const state = ref("");
const postalCode = ref("");
const location = ref(null);
const formSubmitted = ref(false);

const loading = ref(false);

const validators = reactive({
  personal_address: computed(
    () =>
      !ticketStore.attendeeFields.personal_address ||
      (address.value !== "" && formSubmitted.value)
  ),
  postal_code: computed(
    () =>
      !ticketStore.attendeeFields.postal_code ||
      (postalCode.value !== "" && formSubmitted.value)
  ),
});

const isButtonDisabled = computed(() => {
  return address.value === "" && postalCode.value === "" && locality.value;
});

const onUpdateLocation = (event) => {
  console.log("event", event);
  locality.value = event.new;
};

const onBackClick = () => {
  router.push({ name: "MyTicket" });
};

const onContinueClick = async () => {
  const params = {
    attendee: {
      address: address.value,
      postalCode: postalCode.value,
      country: locality.value.country.name,
      locality: locality.value.name,
      state: locality.value.administrative_division,
      city: locality.value._id,
    },
    short_validation_code: ticketStore.ticket.short_validation_code,
  };
  if (ticketStore.ticket.attendee) {
    params.attendee.first_name = ticketStore.ticket.attendee.first_name;
    params.attendee.last_name = ticketStore.ticket.attendee.last_name;
    params.attendee.email = ticketStore.ticket.attendee.email;
    params.attendee.id_card = ticketStore.ticket.attendee.id_card;
    params.attendee.gender = ticketStore.ticket.attendee.gender;
    params.attendee.birthday = ticketStore.ticket.attendee.birthday;
    params.attendee.phone_number = ticketStore.ticket.attendee.phone_number;
  }
  formSubmitted.value = true;
  loading.value = true;
  if (location.value && address.value !== "" && postalCode.value !== "") {
    try {
      await ticketStore.changeAddressFree(params);
      if (!ticketStore.changeAddressError) {
        // Si no hay error y para evitar logearnos de nuevo cambiamos los datos del attendee y redirigimos
        ticketStore.ticket.attendee.address = params.attendee.address;
        ticketStore.ticket.attendee.postalCode = params.postalCode;
        ticketStore.ticket.attendee.country = params.attendee.country;
        ticketStore.ticket.attendee.locality = params.attendee.locality;
        ticketStore.ticket.attendee.state = params.attendee.state;
        ticketStore.ticket.attendee.city = params.attendee.city;
        router.push({ name: "MyTicket" });
      } else {
        const errorData = {
          title: "Fallo en el cambio de dirección",
        };
        openError(errorData);
      }
    } catch (error) {
      console.error(error);
      const errorData = {
        title: "Fallo en el cambio de dirección",
      };
      openError(errorData);
    }
  } else {
    const errorData = {
      title: "Datos incompletos",
      description: "Por favor, rellene todos los campos del formulario.",
    };
    openError(errorData);
  }
  loading.value = false;
};

const openError = (errorData) => {
  openErrorModal({
    modal: {
      title: errorData.title,
      description: errorData.description,
      btn_message: t("myTicket.login.continue"),
    },
  });
};
</script>

<style lang="scss" scoped>
.my-ticket-address {
  display: flex;
  align-items: center;
  justify-content: center;
  &--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1.5rem;
    max-width: 500px;
    width: 100%;
    @media (min-width: 769px) {
      border-radius: 5px;
    }
    &__title {
      width: 100%;
      height: 4rem;
      display: flex;
      & > img {
        cursor: pointer;
        @media (min-width: 769px) {
          display: none;
        }
      }
      & > span {
        margin: auto auto;
      }
    }
    &__actual-address {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 1rem;
      gap: 4px;
    }
    &__new-data {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      gap: 0.5rem;
    }
    &__payment {
      margin-top: 2.75rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      &--info {
        display: flex;
        flex-direction: row;
        background: #ccfffa;
        flex-wrap: nowrap;
        color: #000214;
        padding: 0.5rem;
        border-radius: 5px;
        align-items: flex-start;
        & > img {
          width: 1rem;
          margin-right: 0.5rem;
        }
        & > span {
          font-size: 11px;
          font-weight: lighter;
        }
      }
      &--resume {
        display: flex;
        flex-direction: column;
        background: #404256;
        border-radius: 5px;
        padding: 0.5rem;
        &__row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          & > span {
            font-size: 0.875rem;
          }
        }
      }
    }
    &__button {
      margin-top: 2.813rem;
      padding: 12px 55px !important;
      font-size: 18px !important;
      @media (max-width: 390px) {
        padding: 12px 55px !important;
        font-size: 18px !important;
      }
    }
    &__back {
      font-size: 0.875rem;
      margin-top: 1.375rem;
      text-decoration: underline;
      color: #2effec;
      cursor: pointer;
    }
  }
}

.label {
  font-size: 0.875rem;
  margin: 0.25rem 0;
}

hr {
  color: #7f8295;
}

#total-row {
  margin-top: 1rem;
}

.loader {
  margin-top: 2.813rem;
}
</style>
